import { useCurrentWorldContext } from "../../context/currentWorld";

export interface AllWorldRoutes {
  landing: string,
  agreements: {
    basePath: string,
    eula: string,
    dpa: string
  },
  batteryEssentials: {
    overview: string,
    battery: string,
    devices: string
  },
  batteryWarranty: string,
  bulkActions: string,
  core: {
    overview: string,
    devicesList: string,
    deviceOverview: string,
    devicePerformance: string,
    performance: string,
    anomalies: string,
    anomalyHistory: string,
    anomalyDevices: string,
    applicationsList: string,
    applicationPerformance: string,
    applicationDevicesList: string,
    processesList: string,
    processPerformance: string,
    maps: string
  },
  settings: {
    basePath: string,
    alarms: string,
    alerts: string,
    anomalies: string,
    devices: string,
    platform: string
  },
  userManagement: string,
  homeLocationManagement: string,
  zones: {
    list: string,
    new: string,
    edit: string
  },
  enrollment: string
}

const useRouteWithWorldId = (pathWithLeadingSlash: string) => {
  const { id: worldId } = useCurrentWorldContext();

  return `/${worldId}${pathWithLeadingSlash}`;
};

export const useBaseWorldRoutes = (): AllWorldRoutes => {
  return {
    landing: '/',
    agreements: {
      basePath: '/agreements',
      eula: '/agreements/eula',
      dpa: '/agreements/dpa',
    },
    batteryEssentials: {
      overview: '/battery/overview',
      battery: '/battery',
      devices: '/battery/devices',
    },
    batteryWarranty: '/battery-warranty',
    bulkActions: '/bulkActions',
    core: {
      overview: '/core',
      devicesList: '/core/devices',
      deviceOverview: '/core/devices/info',
      devicePerformance: '/core/devices/performance',
      performance: '/core/performance',
      anomalies: '/core/anomalies',
      anomalyHistory: '/core/anomalies/details',
      anomalyDevices: '/core/anomalies/details/devices',
      applicationsList: '/core/applications',
      applicationPerformance: '/core/applications/performance',
      applicationDevicesList: '/core/applications/devices',
      maps: '/core/maps',
      processesList: '/core/processes',
      processPerformance: '/core/processes/performance'
    },
    settings: {
      basePath: '/settings',
      alarms: '/settings/alarms',
      alerts: '/settings/alerts',
      anomalies: '/settings/anomalies',
      devices: '/settings/devices',
      platform: '/settings/platform'
    },
    userManagement: '/users',
    homeLocationManagement: '/homeLocations',
    zones: {
      list: '/zones',
      new: '/zones/new',
      edit: '/zones/edit'
    },
    enrollment: '/enrollment'
  };
};

export const useWorldRoutes = (): AllWorldRoutes => {
  return {
    landing: useRouteWithWorldId(useBaseWorldRoutes().landing),
    agreements: {
      basePath: useRouteWithWorldId(useBaseWorldRoutes().agreements.basePath),
      eula: useRouteWithWorldId(useBaseWorldRoutes().agreements.eula),
      dpa: useRouteWithWorldId(useBaseWorldRoutes().agreements.dpa)
    },
    batteryEssentials: {
      overview: useRouteWithWorldId(useBaseWorldRoutes().batteryEssentials.overview),
      battery: useRouteWithWorldId(useBaseWorldRoutes().batteryEssentials.battery),
      devices: useRouteWithWorldId(useBaseWorldRoutes().batteryEssentials.devices),
    },
    batteryWarranty: useRouteWithWorldId(useBaseWorldRoutes().batteryWarranty),
    bulkActions: useRouteWithWorldId(useBaseWorldRoutes().bulkActions),
    core: {
      overview: useRouteWithWorldId(useBaseWorldRoutes().core.overview),
      devicesList: useRouteWithWorldId(useBaseWorldRoutes().core.devicesList),
      deviceOverview: useRouteWithWorldId(useBaseWorldRoutes().core.deviceOverview),
      devicePerformance: useRouteWithWorldId(useBaseWorldRoutes().core.devicePerformance),
      performance: useRouteWithWorldId(useBaseWorldRoutes().core.performance),
      anomalies: useRouteWithWorldId(useBaseWorldRoutes().core.anomalies),
      anomalyHistory: useRouteWithWorldId(useBaseWorldRoutes().core.anomalyHistory),
      anomalyDevices: useRouteWithWorldId(useBaseWorldRoutes().core.anomalyDevices),
      applicationsList: useRouteWithWorldId(useBaseWorldRoutes().core.applicationsList),
      applicationPerformance: useRouteWithWorldId(useBaseWorldRoutes().core.applicationPerformance),
      applicationDevicesList: useRouteWithWorldId(useBaseWorldRoutes().core.applicationDevicesList),
      maps: useRouteWithWorldId(useBaseWorldRoutes().core.maps),
      processesList: useRouteWithWorldId(useBaseWorldRoutes().core.processesList),
      processPerformance: useRouteWithWorldId(useBaseWorldRoutes().core.processPerformance)
    },
    settings: {
      basePath: useRouteWithWorldId(useBaseWorldRoutes().settings.basePath),
      alarms: useRouteWithWorldId(useBaseWorldRoutes().settings.alarms),
      alerts: useRouteWithWorldId(useBaseWorldRoutes().settings.alerts),
      anomalies: useRouteWithWorldId(useBaseWorldRoutes().settings.anomalies),
      devices: useRouteWithWorldId(useBaseWorldRoutes().settings.devices),
      platform: useRouteWithWorldId(useBaseWorldRoutes().settings.platform)
    },
    userManagement: useRouteWithWorldId(useBaseWorldRoutes().userManagement),
    homeLocationManagement: useRouteWithWorldId(useBaseWorldRoutes().homeLocationManagement),
    zones: {
      list: useRouteWithWorldId(useBaseWorldRoutes().zones.list),
      new: useRouteWithWorldId(useBaseWorldRoutes().zones.new),
      edit: useRouteWithWorldId(useBaseWorldRoutes().zones.edit)
    },
    enrollment: useRouteWithWorldId(useBaseWorldRoutes().enrollment)
  };
};
