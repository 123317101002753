import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import { get, isEqual } from 'lodash';

import {
  getSelectedDateDeviceUsage,
  getSelectedDateDeviceDataUsage,
  getSelectedDateDeviceChargeLevel,
  getSelectedDatePerformanceOverview,
  getSelectedDateDeviceEventsList
} from '../../../../../services/core/devicePerformance';
import { Loading } from '../../../../../components/loading/loading';
import { DeviceUsage24Hours } from './charts/24HourDeviceUsage';
import { DataUsage24Hours } from './charts/24HourDataUsage';
import { DeviceChargeLevel } from './charts/deviceChargeLevel';
import { DevicePerformanceOverview } from './devicePerformanceOverview';
import { usePrevious } from '../../../../../lib/usePrevious';
import { EditEventsAction, getActiveEventFilters } from './events/edit-events';
import { IDeviceEventFilterState } from './events/deviceEventsTypes';
import { DeviceEvent, DeviceEventName } from '../../../../../services/core/eventsTypes';
import { getPerformanceOverviewEvents, filterDeviceEvents, getDeviceEvents } from './events/deviceEvents';
import { PerformanceEvents, PerformanceView } from './performance-events';
import { DeviceInfoContext } from '../../index';
import { ChartName } from './charts/lib/chartNames';
import { TableName } from '../../../../../components/data-table/lib';
import { useAlignCharts } from '../../../../../lib/useAlignCharts';
import { RequestInitWithRetry } from '../../../../../lib/request';
import { useWorldRequest } from '../../../../../lib/useWorldRequest';
import { useFeatureTogglesContext } from '../../../../../context/featureToggles';

import './reports.css';

interface IProps {
  selectedDate: number,
  eventFiltersDeviceUsage: IDeviceEventFilterState,
  eventFiltersDataUsage: IDeviceEventFilterState,
  eventFiltersDeviceChargeLevel: IDeviceEventFilterState,
  eventFiltersEventsList: IDeviceEventFilterState,
  dispatchChartEventFilters: React.Dispatch<EditEventsAction<ChartName>>,
  dispatchTableEventFilters: React.Dispatch<EditEventsAction<TableName>>,
  visible: boolean
}

interface IRequestParams {
  deviceId: string,
  to: number,
  events?: DeviceEventName[]
}

function getRequestParams(deviceId: string, dates: { to: number }): IRequestParams {
  const { to } = dates;
  return { deviceId, to };
}

export function SelectedDateReport(props: IProps) {
  const {
    selectedDate,
    eventFiltersDeviceUsage,
    eventFiltersDataUsage,
    eventFiltersDeviceChargeLevel,
    eventFiltersEventsList,
    dispatchChartEventFilters,
    dispatchTableEventFilters,
    visible
  } = props;
  const featureToggles = useFeatureTogglesContext();
  const { platformType, id: deviceId } = useContext(DeviceInfoContext);

  const { t } = useTranslation('performance');

  const [allEvents, setAllEvents] = useState([]);
  const [eventsFetched, setEventsFetched] = useState(false);
  const [deviceUsageEvents, setDeviceUsageEvents] = useState([]);
  const prevEventFiltersDeviceUsage = usePrevious(eventFiltersDeviceUsage);
  const [dataUsageEvents, setDataUsageEvents] = useState([]);
  const prevEventFiltersDataUsage = usePrevious(eventFiltersDataUsage);
  const prevEventFiltersDeviceChargeLevel = usePrevious(eventFiltersDeviceChargeLevel);
  const [deviceChargeLevelEvents, setDeviceChargeLevelEvents] = useState([]);

  const { addChart, clearCharts } = useAlignCharts(3);

  const dates = useMemo(() => ({
    from: moment.utc(selectedDate).startOf('day').valueOf(),
    to: moment.utc(selectedDate).endOf('day').valueOf()
  }), [selectedDate]);
  const dropsAreEnabled = platformType === 'android';

  const performanceOverviewEvents = useMemo(() => {
    return getPerformanceOverviewEvents(dropsAreEnabled);
  }, [dropsAreEnabled]);

  const deviceEventNames = useMemo(() => {
    return Object.values(getDeviceEvents(platformType, featureToggles)).map(event => event.name);
  }, [platformType, featureToggles]);

  const dataFetcher = useCallback(() => {
    if (deviceId) {
      clearCharts();
      const params = getRequestParams(deviceId, dates);
      return (options: RequestInitWithRetry) => {
        return Promise.all([
          getSelectedDateDeviceDataUsage(params)(options),
          getSelectedDateDeviceUsage(params)(options),
          getSelectedDateDeviceChargeLevel(params)(options)
        ]);
      };
    }
  }, [clearCharts, deviceId, dates]);

  const {
    loading: dataLoading,
    data: [dataUsage, deviceUsage, batteryLevels]
  } = useWorldRequest(dataFetcher, { initialLoading: true, initialData: [[], [], []] });

  const onEventsFetched = useCallback((events: DeviceEvent[]) => {
    setAllEvents(events);
    setEventsFetched(true);
  }, []);

  const eventsDataFetcher = useCallback(() => {
    if (deviceId) {
      const params = getRequestParams(deviceId, dates);
      return getSelectedDateDeviceEventsList({ ...params, events: deviceEventNames });
    }
  }, [deviceId, dates, deviceEventNames]);

  const {
    loading: eventsLoading,
  } = useWorldRequest(eventsDataFetcher, { initialLoading: false, initialData: [], onSuccess: onEventsFetched });

  const overviewDataFetcher = useCallback(() => {
    if (deviceId) {
      const params = {
        ...getRequestParams(deviceId, dates),
        events: performanceOverviewEvents
      };
      return getSelectedDatePerformanceOverview(params);
    }
  }, [deviceId, dates, performanceOverviewEvents]);

  const {
    data: overviewData
  } = useWorldRequest(overviewDataFetcher, { initialData: undefined });

  const activeEventsDeviceUsage = useMemo(() => getActiveEventFilters(eventFiltersDeviceUsage.appliedEventFilters), [eventFiltersDeviceUsage.appliedEventFilters]);
  const activeEventsDataUsage = useMemo(() => getActiveEventFilters(eventFiltersDataUsage.appliedEventFilters), [eventFiltersDataUsage.appliedEventFilters]);
  const activeEventsDeviceChargeLevel = useMemo(() => getActiveEventFilters(eventFiltersDeviceChargeLevel.appliedEventFilters), [eventFiltersDeviceChargeLevel.appliedEventFilters]);

  useEffect(() => {
    const [deviceUsageEvents, dataUsageEvents, chargeLevelEvents] = filterDeviceEvents(
      allEvents, activeEventsDeviceUsage, activeEventsDataUsage, activeEventsDeviceChargeLevel
    );
    setDeviceUsageEvents(deviceUsageEvents);
    setDataUsageEvents(dataUsageEvents);
    setDeviceChargeLevelEvents(chargeLevelEvents);
  }, [activeEventsDataUsage, activeEventsDeviceChargeLevel, activeEventsDeviceUsage, allEvents]);

  useEffect(() => {
    // if we already have the required events in state, check which charts need to be updated
    const deviceUsageFiltersChanged = !isEqual(eventFiltersDeviceUsage.appliedEventFilters, get(prevEventFiltersDeviceUsage, 'appliedEventFilters'));
    const dataUsageFiltersChanged = !isEqual(eventFiltersDataUsage.appliedEventFilters, get(prevEventFiltersDataUsage, 'appliedEventFilters'));
    const deviceChargeLevelFiltersChanged = !isEqual(eventFiltersDeviceChargeLevel.appliedEventFilters, get(prevEventFiltersDeviceChargeLevel, 'appliedEventFilters'));
    const anyFiltersChanged = deviceUsageFiltersChanged || dataUsageFiltersChanged || deviceChargeLevelFiltersChanged;

    if (eventsFetched && anyFiltersChanged) {
      const [deviceUsageEvents, dataUsageEvents, chargeLevelEvents] = filterDeviceEvents(
        allEvents, activeEventsDeviceUsage, activeEventsDataUsage, activeEventsDeviceChargeLevel
      );
      deviceUsageFiltersChanged && setDeviceUsageEvents(deviceUsageEvents);
      dataUsageFiltersChanged && setDataUsageEvents(dataUsageEvents);
      deviceChargeLevelFiltersChanged && setDeviceChargeLevelEvents(chargeLevelEvents);
    }
  }, [
    eventFiltersDataUsage.appliedEventFilters, eventFiltersDeviceChargeLevel.appliedEventFilters,
    eventFiltersDeviceUsage.appliedEventFilters, eventsFetched, prevEventFiltersDataUsage,
    prevEventFiltersDeviceChargeLevel, prevEventFiltersDeviceUsage, allEvents, activeEventsDeviceUsage,
    activeEventsDataUsage, activeEventsDeviceChargeLevel,
  ]);

  function formatDate(date: number) {
    return `${moment.utc(date).format('LLLL')} ${t('AT')} ${moment.utc(date).format('LT')}`;
  }

  const classname = 'selected-date';

  return (
    <div className="core-device_performance-selected-date-report">
      <DevicePerformanceOverview
        from={formatDate(dates.from)}
        to={formatDate(dates.to)}
        data={overviewData}
        dropsAreEnabled={dropsAreEnabled}
        historic
      />
      <div className='selectedDateCharts_container'>
        <Loading isLoading={dataLoading || eventsLoading} transparentOverlay={false}>
          <DeviceUsage24Hours
            classname={classname}
            data={deviceUsage}
            events={deviceUsageEvents}
            eventFilters={eventFiltersDeviceUsage}
            dispatchEventFilters={dispatchChartEventFilters}
            visible={visible}
            onMount={addChart}
          />
          <DataUsage24Hours
            classname={classname}
            data={dataUsage}
            events={dataUsageEvents}
            eventFilters={eventFiltersDataUsage}
            dispatchEventFilters={dispatchChartEventFilters}
            visible={visible}
            onMount={addChart}
          />
          <DeviceChargeLevel
            classname={classname}
            dateRange={dates}
            data={batteryLevels}
            events={deviceChargeLevelEvents}
            eventFilters={eventFiltersDeviceChargeLevel}
            dispatchEventFilters={dispatchChartEventFilters}
            visible={visible}
            onMount={addChart}
          />
        </Loading>
      </div>
      <PerformanceEvents eventFilters={eventFiltersEventsList} dispatchEventFilters={dispatchTableEventFilters} to={dates.to} currentView={PerformanceView.selectedDate} />
    </div>
  );
}
