import { useTranslation } from "@lib/useTypedTranslation";


import { formatDateTime, formatDeviceEvents, formatEventInfo } from "../../../../../components/data-table/dataFormatters";
import { ITableColumn } from "../../../../../components/data-table/types";

export const usePerformanceEventsListColumns = () => {
  const { t } = useTranslation('performance');

  const columns: ITableColumn[] = [
    { id: 'eventType', title: t('EVENT_TYPE'), dataPath: 'name', sortable: false, processor: formatDeviceEvents, textAlign: 'left', width: '10rem' },
    { id: 'eventTime', title: t('EVENT_TIME'), dataPath: 'local', sortable: true, processor: formatDateTime, textAlign: 'left', width: '7rem' },
    { id: 'eventInformation', title: t('EVENT_INFORMATION'), dataPath: undefined, processor: formatEventInfo, textAlign: 'left', width: '17rem' }
  ];

  return columns;
};
