import { cloneDeep } from 'lodash';

import { DeviceEventName, DeviceEvent } from '../../../../../../services/core/eventsTypes';
import { IEventFilterGroups } from './edit-events';
import { omitShallow } from '../../../../../../lib/omitShallow';
import { deviceEventFilterGroups, deviceEvents, DeviceEvents, deviceEventFiltersArray } from './deviceEventsTypes';
import { FeatureTogglesContextValue } from '../../../../../../context/featureToggles';

export function getPerformanceOverviewEvents(dropsAreEnabled: boolean): DeviceEventName[] {
  const events: DeviceEventName[] = ['deviceRebooted', 'deviceLowPower', 'timeError', 'batteryChanged'];
  if (dropsAreEnabled) {
    events.push('deviceDropped');
  }
  return events;
}

export function getDeviceEventFilterGroups(platformType: string, featureToggles: FeatureTogglesContextValue): IEventFilterGroups {
  let filterGroups = cloneDeep(deviceEventFilterGroups);
  if (platformType === 'android') {
    filterGroups = filterGroups.set('userExperience', filterGroups.get('userExperience').filter(group => !group.name.startsWith('process')));
  } else {
    filterGroups = filterGroups.set('userExperience', filterGroups.get('userExperience').filter(group => group.name !== 'deviceDropped'));
  }
  return filterGroups;
}

export function getDeviceEvents(platformType: string, featureToggles: FeatureTogglesContextValue): DeviceEvents {
  const eventsToRemove: DeviceEventName[] = [];
  if (platformType === 'android') {
    eventsToRemove.push('processCrashed');
    eventsToRemove.push('processHung');
  } else {
    eventsToRemove.push('deviceDropped');
  }
  return omitShallow<DeviceEvents>(deviceEvents, eventsToRemove);
}

/** Comparison function for consistent device event name ordering */
export const sortDeviceEventFilters = (a: string, b: string) => {
  const aIndex = deviceEventFiltersArray.findIndex(f => f.name === a);
  const bIndex = deviceEventFiltersArray.findIndex(f => f.name === b);
  if (aIndex > bIndex) {
    return 1;
  } else if (aIndex < bIndex) {
    return -1;
  }
  return 0;
};

/** Filter device events by name, into an array per supplied filter */
export const filterDeviceEvents = (events: DeviceEvent[], ...filters: DeviceEventName[][]) => {
  return events.reduce((acc, event) => {
    filters.forEach((filter, i) => {
      filter.includes(event.name) && acc[i].push(event);
    });
    return acc;
  }, Array.from(Array(filters.length), () => []));
};
